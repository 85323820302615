import { Request } from '@/plugins/helper/fetch'
const req = new Request('category');
const qrList = (cat_id) => {
  const URL = cat_id === 'chung' ? 'list/chung':`list?cat=${cat_id}`;
  return req.fetch(URL);
}
const qrListServices = () => {
  const URL = 'list/services';
  return req.fetch(URL);
}
const qrUpdate = (cat_id, id, {status, ten, top}) => req.patch(`update/${id}?cat=`+cat_id,{status, ten, top});
const qrUpdateChung = (id, {Ten, AMZ, RKT, YS, YA}) => req.patch(`update/${id}?cat=chung`,{Ten, AMZ, RKT, YS, YA});
const qrCreate = (createData) => req.put(`create`, createData);
const qrUploadFile = (uri, file) => req.UploadFile(uri, file);
const qrDelete = (id) => req.remove(`/delete/${id}`);
export default {
  qrList,
  qrUpdate,
  qrListServices,
  qrCreate,
  qrUploadFile,
  qrUpdateChung,
  qrDelete,
}