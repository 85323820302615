<template>
	<v-container fluid>
		<v-layout row wrap>
			<v-flex
				sm12
				xs12
				md12
				lg12
			>
				<template v-if="cat_id !== null">
					<v-card>
					<v-toolbar color="#0f2e8c" flat>
						<h3 class="white--text" v-if="cat_id !== 'chung'">Danh mục: {{CategoryDB[cat_id].name}}</h3>
            <h3 class="white--text" v-else>Danh mục chung</h3>
						<v-spacer />
            <v-btn
              v-if="cat_id === 'chung'"
              dark
              color="green"
              @click="doCreateNew()"
            >
              Thêm
            </v-btn>
					</v-toolbar>
					<v-card-title>
						<v-row>
							<v-col
								cols="auto"
							>

							</v-col>
							<v-spacer></v-spacer>
							<v-col
								cols="12"
								md="6"
								lg="4"
							>
								<v-text-field
									v-model="Search"
									append-icon="search"
									label="Từ khóa 🔎"
									single-line
									hide-details
								/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text>
            <template v-if="cat_id">
              <v-data-table
                :loading="loading"
                :headers="cat_id === 'chung' ? headersAll:headers"
                :items="items"
                :search="Search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
              >
                <template v-slot:[`item.Image`]="{item}">
                  <template v-if="item.Image && item.Image.length > 0">
                    <v-img
                        :src="`${statics}/${item.Image}`"
                        :lazy-src="`${statics}/${item.Image}`"
                        contain
                        class="justify-center align-center row"
                        height="84"
                        width="84"
                    />
                  </template>
                </template>
                <template v-slot:[`item.Service`]="{item}">
                  <div
                    cols="12"
                    v-for="(item,index) in item.Services"
                  >
                    <template v-if="item">
                      <strong>{{ServiceName[index]}}</strong>: {{ServiceData[index] && ServiceData[index].filter(s=>s._id === item)[0]['tenvn']}}
                    </template>
                  </div>
                  <v-divider />
                  <v-row>
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                          icon
                          color="red"
                          @click="doDelete(item._id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                    >
                      <v-btn
                          icon
                          color="green"
                          @click="doOpenUpdate(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                    >
                      <v-btn
                          :loading="uploadState === item._id"
                          :disabled="uploadState === item._id"
                          :dark="!uploadState"
                          color="blue"
                          class="ma-2 white--text"
                          @click="doStartUpload(item._id)"
                          small
                      >
                        Upload
                        <v-icon
                            right
                            dark
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.te_hidden`]="{ item }">
                  <v-btn
                    color="red"
                    outlined
                    dark
                    small
                    v-if="item['te_hidden'] === true"
                    @click="doHidden(item._id,false)"
                    :disabled="loaderStatus"
                    :loading="loaderStatus"
                  >
                    Không hiển thị
                  </v-btn>
                  <v-btn
                    color="green"
                    outlined
                    dark
                    small
                    v-else
                    :disabled="loaderStatus"
                    :loading="loaderStatus"
                    @click="doHidden(item._id,true)"
                  >
                    Đang hiển thị
                  </v-btn>
                </template>
                <template v-slot:[`item.top`]="{ item }">
                  <v-btn
                    color="green"
                    outlined
                    dark
                    small
                    v-if="item['top'] === true"
                    :disabled="loaderTop"
                    :loading="loaderTop"
                  >
                    Đang là TOP
                  </v-btn>
                  <v-btn
                    color="blue"
                    dark
                    small
                    v-else
                    :disabled="loaderTop"
                    :loading="loaderTop"
                    @click="doTop(item._id)"
                  >
                    Đặt là TOP
                  </v-btn>
                </template>
              </v-data-table>
            </template>
            <template v-else>

            </template>
          </v-card-text>
				</v-card>
				</template>
				<template v-else>
					<v-alert
						type="error"
					>
						THÔNG TIN DANH MỤC KHÔNG HỢP LỆ
					</v-alert>
				</template>
			</v-flex>
		</v-layout>

    <v-file-input
        ref="uploader"
        v-model="file"
        accept="image/*"
        truncate-length="15"
        label="Tải lên hình ảnh"
        :loading="uploadState"
        @change="doUploadFiles"
        class="hide"
    />
    <v-dialog
      v-model="boxAdd"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <template v-if="!updateID">
            Thêm danh mục mới
          </template>
          <template v-else>
            Thay đổi thông tin danh mục
          </template>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="CategoryData.Ten"
                class="mx-2"
                label="Tên danh mục"
              />
            </v-col>
            <v-divider />
            <div class="text--accent-2">Danh mục liên kết</div>
            <v-col
                cols="12"
            >
              <v-autocomplete
                  v-model="CategoryData.AMZ"
                  :items="ServiceData[0]"
                  item-value="_id"
                  item-text="tenvn"
                  class="mx-2"
                  label="Amazon"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <v-autocomplete
                  v-model="CategoryData.RKT"
                  :items="ServiceData[1]"
                  item-value="_id"
                  item-text="tenvn"
                  class="mx-2"
                  label="Rakuten"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <v-autocomplete
                  v-model="CategoryData.YS"
                  :items="ServiceData[2]"
                  item-value="_id"
                  item-text="tenvn"
                  class="mx-2"
                  label="Y! Shopping"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <v-autocomplete
                  v-model="CategoryData.YA"
                  :items="ServiceData[3]"
                  item-value="_id"
                  item-text="tenvn"
                  class="mx-2"
                  label="Y! Auction"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <v-autocomplete
                  v-model="CategoryData.MCR"
                  :items="ServiceData[4]"
                  item-value="_id"
                  item-text="tenvn"
                  class="mx-2"
                  label="Mercari"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :dark="!createState"
            color="green"
            @click="doCreate()"
            :loading="createState"
            :disabled="createState"
            v-if="!updateID"
          >
            Tạo mới
          </v-btn>
          <v-btn
              :dark="!createState"
              color="green"
              @click="doUpdateChung()"
              :loading="createState"
              :disabled="createState"
              v-if="updateID"
          >
            Cập nhật
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            @click="boxAdd = false"
          >
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import Query from '@/plugins/query/category.js';
import { Status } from '@/plugins/helper/dstatus'
import Loading from '@/components/base/Loading.vue'
import CategoryDB from '@/json/category.json';
import Global from "@/global";
export default {
	name: "Tags",
	components:{
		Loading,
	},
	data(){
		return {
			CategoryDB,
      updateID: null,
      uploadID: null,
      file: null,
      uploadState:null,
      ServiceData: [],
      CategoryData: {
        Ten: '',
        AMZ: null,
        RKT: null,
        YS: null,
        YA: null,
        MCR: null,
      },
			loader: false,
			loaderTop: false,
			loaderStatus: false,
			Search: null,
			boxAdd: false,
			loading: false,
      createState: false,
      ServiceName: [
          "Amazon",
          "Rakuten",
          "Y! Shopping",
          "Y! Auction",
          "Mercari",
      ],
			headers: [
				{ text: 'Tên', value: 'ten', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Hiển thị', value: 'te_hidden', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
				{ text: 'Top sản phẩm', value: 'top', align: 'center',width: "200px",class:"grey lighten-2 black--text",sortable:false },
			],
      headersAll: [
        { text: 'Tên', value: 'Ten', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Hình ảnh', value: 'Image', align: 'center',width: "150px",class:"grey lighten-2 black--text",sortable:false },
        { text: 'Dịch vụ', value: 'Service', align: 'center',width: "250px",class:"grey lighten-2 black--text",sortable:false },
      ],
			items: [],
			notice: new Status(this.$swal),
			Edit: {
				id: null,
				vi: null,
				ja:null,
			},
		}
	},
	computed: {
		...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
		cat_id(){
			return this.$route.params.id;
		},
    statics(){
      return `${Global.staticTOP}/shop-images`;
    },
	},
	watch:{
		cat_id(){
			this.doDownload();
		}
	},
	methods:{
		...Query,
    doDelete(id){
      const app = this;
      this.notice.confirm('Xác nhận ?','Hành động này sẽ thay đổi dữ liệu và không thể phục hồi. Bạn có xác nhận ?').then(success=> {
        if (success) {
          app.loading = true;
          app.qrDelete(id).then(response => {
            const {error} = response;
            if (error) {
              app.notice.error(error);
              return;
            }
            app.notice.success('Đã xóa thành công !!!');
            app.doDownload();
          })
          .finally(() => {
            app.loading = false;
          })
        }
      });
    },
    doCreateNew(){
      this.updateID = null;
      this.CategoryData = {
        Ten: '',
        AMZ: null,
        RKT: null,
        YS: null,
        YA: null,
        MCR: null,
      };
      this.boxAdd = true;
    },
    doStartUpload(id){
      this.uploadID = id;
      this.$refs.uploader.$refs.input.click();
    },
    doUploadFiles(){
      if(this.file){
        this.uploadState = this.uploadID;
        let Tasks = [];
        Tasks.push(this.qrUploadFile(`/image/${this.uploadID}`,this.file));
        return Promise.all(Tasks).then(this.doDownload).finally(()=>{
          this.uploadState = false;
          this.file = null;
          this.uploadID = null;
        });
      }else{
        this.Notice.error('Vui lòng chọn file để tải lên !!!');
      }
    },
    doOpenUpdate(data){
      this.updateID = data._id;
      this.CategoryData = {
        Ten: data.Ten,
        AMZ: data.AMZ,
        RKT: data.RKT,
        YS: data.YS,
        YA: data.YA,
        MCR: data.MCR
      };
      this.boxAdd = true;
    },
    doUpdateChung(){
      this.createState = true;
      return this.qrUpdateChung(this.updateID, this.CategoryData).then(resp=>resp.json()).then(json=> {
        const {data, success, error} = json;
        if(success){
          this.boxAdd = false;
          this.notice.success("Đã cập nhật thành công !!!");
          this.CategoryData = {
            Ten: '',
            AMZ: null,
            RKT: null,
            YS: null,
            YA: null
          };
          return this.doDownload();
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(e=>{
        console.log(e);
        this.notice.error(e);
      })
          .finally(()=>{
            this.createState = false;
          });
    },
    doCreate(){
      this.createState = true;
      return this.qrCreate(this.CategoryData).then(resp=>resp.json()).then(json=> {
        const {data, success, error} = json;
        if(success){
          this.boxAdd = false;
          this.notice.success("Đã khởi tạo thành công !!!");
          this.CategoryData = {
            Ten: '',
            AMZ: null,
            RKT: null,
            YS: null,
            YA: null,
            MCR: null,
          };
          return this.doDownload();
        }
        if(error){
          this.notice.error(error);
        }
      }).catch(e=>{
        console.log(e);
        this.notice.error(e);
      })
      .finally(()=>{
        this.createState = false;
      });
    },
		doHidden(id, status){
			this.loaderStatus = true;
			this.qrUpdate(this.cat_id, id, {status}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					this.notice.success("Cập nhật thành công");
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi cập nhật !!!';
					this.notice.error(msg);
				}
			}).catch(e=>{
				console.error(e);
				const msg = e.message || 'Lỗi hệ thống !!!';
				this.notice.error("Lỗi hệ thống: "+msg);
			}).finally(()=>{
				this.loaderStatus = false;
			})
		},
		doTop(id){
			this.loaderTop = true;
			this.qrUpdate(this.cat_id, id, {top: true}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					this.notice.success("Cập nhật thành công");
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi cập nhật !!!';
					this.notice.error(msg);
				}
			}).catch(e=>{
				console.error(e);
				const msg = e.message || 'Lỗi hệ thống !!!';
				this.notice.error("Lỗi hệ thống: "+msg);
			}).finally(()=>{
				this.loaderTop = false;
			})
		},
		doUpdate(){
			const { id, vi, ja } = this.Edit;
			if(id === null){
				this.notice.error("Không tìm thấy dữ liệu");
				return;
			}
			this.qrUpdate(id, {vi,ja}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					this.notice.success("Cập nhật thành công");
					this.Edit = {
						id: null,
						vi: null,
						ja:null,
					}
					this.doDownload();
				}
				if(error){
					const msg = json.error || 'Có lỗi khi cập nhật !!!';
					this.notice.error(msg);
				}
			}).catch(e=>{
				console.error(e);
				const msg = e.message || 'Lỗi hệ thống !!!';
				this.notice.error("Lỗi hệ thống: "+msg);
			})
		},
		doDownload(){
			return this.qrList(this.cat_id).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {data} = json;
				if(data){
					this.items = data;
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e);
			});
		},
    doDownloadServices(){
      return this.qrListServices().then(resp=>{
        return resp.json();
      }).then(json=>{
        const {data} = json;
        if(data){
          this.ServiceData = data;
        }
      }).catch(e=>{
        console.error(e);
        this.notice.error(e);
      });
    }
	},
	mounted(){
		if(this.loginInfo._perm !== 'admin'){
			this.$router.push('/');
			return;
		}
    let Tasks = [];
		if(this.cat_id){
      Tasks.push(this.doDownload());
      if(this.cat_id === 'chung'){
        Tasks.push(this.doDownloadServices());
      }
      this.loading = true;
      Promise.all(Tasks).finally(()=>{
        this.loading = false;
      });
		}
	}
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>